










































































































import Vue, { VueConstructor } from 'vue';
import { required, maxLength, minLength, email } from 'vuelidate/lib/validators';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import AddressFormValidations from '@/validations/AddressFormValidations';
import OwnerAddressService from '@/services/owner/OwnerAdressService';
import SelectDto from '@/models/SelectDto';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';
import PhoneNumberValidator from '@/validators/PhoneNumberValidator';

class PlayroomEditOwnerInformationPartViewModel {
  id!: string;
  city!: string;
  country!: string;
  state!: string;
  street!: string;
  houseNumber!: string;
  zipCode!: string;
  latitude!: number;
  longitude!: number;
  ownerTitle!: string;
  ownerFirstName!: string;
  ownerLastName!: string;
  ownerIsManager!: boolean;
  email!: string;
  phoneNumber!: string;
  companyLegalName!: string;
  companyLegalType!: string;
  companyLegalTypes!: string[];
  companyName!: string;
}

const validations = {
  playroom: {
    ...AddressFormValidations,
    ownerTitle: { required },
    ownerFirstName: { required, maxLength: maxLength(25) },
    ownerLastName: { required, maxLength: maxLength(25) },
    ownerIsManager: { required },
    email: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50),
      email,
    },
    phoneNumber: { required, maxLength: maxLength(20), PhoneNumberValidator },
    companyName: {},
    companyLegalType: {},
    companyLegalName: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, AddressForm, ValidatedFormCard, ValidatedCheckbox, ValidatedSelect },
  mixins: [validationMixin, HelpCardMixin],
  validations() {
    var vals = { ...validations };
    if (!!this.playroom.companyName) {
      vals.playroom.companyLegalType = { required };
    } else {
      vals.playroom.companyLegalType = {};
    }
    if (!!this.playroom.companyLegalType) {
      vals.playroom.companyName = { required };
    } else {
      vals.playroom.companyName = {};
    }
    if (this.playroom.companyLegalType == 'Other') {
      vals.playroom.companyLegalName = { required };
    } else {
      vals.playroom.companyLegalName = {};
    }
    return vals;
  },
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditOwnerInformationPartViewModel,
      ready: false,
    };
  },
  computed: {
    titleOptions(): SelectDto[] {
      return [
        { value: 'mr', label: this.$t('components.playroomEditParts.OwnerInformationPart.title_mr').toString() },
        { value: 'mrs', label: this.$t('components.playroomEditParts.OwnerInformationPart.title_mrs').toString() },
        {
          value: 'hello',
          label: this.$t('components.playroomEditParts.OwnerInformationPart.title_hello').toString(),
        },
      ];
    },
    email(): string {
      return this.playroom.email;
    },
    companyLegalTypeOptions(): { value: string; label: string }[] {
      if (this.ready && this.playroom.hasOwnProperty('companyLegalTypes')) {
        let cmpLegal = [];
        cmpLegal = this.playroom.companyLegalTypes.map((t) => ({
          value: t as string,
          label: this.$t('enums.companyLegalTypes.' + t).toString(),
        }));
        cmpLegal.splice(0, 0, { value: '', label: '' });
        return cmpLegal;
      } else {
        return [];
      }
    },
    companyLegalType(): string {
      return this.playroom.companyLegalType;
    },
    companyName(): string {
      return this.playroom.companyName;
    },
    isCompanyLegalNameDisabled(): boolean {
      return this.playroom.companyLegalType == 'Other';
    },
  },
  mounted() {
    this.loadData();
  },
  watch: {
    playroom: {
      deep: true,
      immediate: true,
      handler() {
        if (this.playroom) {
          this.$emit('ownerIsManagerChange', this.playroom.ownerIsManager);
        }
      },
    },
    email: {
      handler() {
        if (!this.playroom.email) return;
        this.playroom.email = this.playroom.email.trim();
      },
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditOwnerInformationPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditOwnerInformationPartViewModel;
        this.playroom.companyLegalTypes.shift();
        if (this.playroom.companyLegalType == 'None') {
          this.playroom.companyLegalType = '';
        }
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.OwnerInformationPart.info.box1';
      }
    },
    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveOwnerInformationPart({ ...this.playroom });
        return response.success;
      } else {
        return false;
      }
    },

    onChange() {
      if (!this.$v.$invalid) {
        // Geocode new Address
        var addressObj = {
          street: this.playroom.street,
          houseNumber: this.playroom.houseNumber,
          city: this.playroom.city,
          state: this.playroom.state,
          zipCode: this.playroom.zipCode,
          country: this.playroom.country,
        };
        OwnerAddressService.geocodeAddress(addressObj).then((data) => {
          // Save Lat and Lng
          this.playroom.latitude = data.value.latitude;
          this.playroom.longitude = data.value.longitude;
        });
      }
    },
  },
});
