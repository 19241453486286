


































import Vue, { VueConstructor } from 'vue';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import AddressForm from '@/components/forms/AddressForm.vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import { validationMixin } from 'vuelidate';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import AddressFormValidations from '@/validations/AddressFormValidations';
import OwnerAddressService from '@/services/owner/OwnerAdressService';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

class PlayroomEditSummaryPartViewModel {
  id!: string;
  name!: string;
  city!: string;
  country!: string;
  state!: string;
  street!: string;
  houseNumber!: string;
  zipCode!: string;
  latitude!: number;
  longitude!: number;
}

const validations = {
  playroom: {
    ...AddressFormValidations,
    name: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(100),
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, AddressForm, ValidatedFormCard },
  mixins: [validationMixin, HelpCardMixin],
  validations,
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomEditSummaryPartViewModel,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditSummaryPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomEditSummaryPartViewModel;
        this.ready = true;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      } else {
        this.focusFor = 'components.playroomEditParts.SummaryPart.info.box1';
      }
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveSummaryPart({ ...this.playroom });
        return response.success;
      } else {
        return false;
      }
    },

    onChange() {
      if (!this.$v.$invalid) {
        // Geocode new Address
        var addressObj = {
          street: this.playroom.street,
          houseNumber: this.playroom.houseNumber,
          city: this.playroom.city,
          state: this.playroom.state,
          zipCode: this.playroom.zipCode,
          country: this.playroom.country,
        };
        OwnerAddressService.geocodeAddress(addressObj).then((data) => {
          // Save Lat and Lng
          this.playroom.latitude = data.value.latitude;
          this.playroom.longitude = data.value.longitude;
        });
      }
    },
  },
});
