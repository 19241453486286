import ApiService from '@/services/ApiService';

const API_URL = '/api/owner/Address/';

class OwnerAddressService {
  geocodeAddress(addressObj: {
    street: string;
    houseNumber: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
  }) {
    return ApiService.post(`${API_URL}GeocodeAddress`, addressObj);
  }
}

export default new OwnerAddressService();
