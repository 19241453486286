






























































import Vue from 'vue';
import ValidatedCheckbox from '@/components/forms/ValidatedCheckbox.vue';
import ValidatedSwitch from '@/components/forms/ValidatedSwitch.vue';
import { validationMixin } from 'vuelidate';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import ValidatedFormCard from '@/components/shared/ValidatedFormCard.vue';
import { ValidatedFormCardType } from '@/models/ValidatedFormCardType';
import { PartSaveStatusType } from '@/enums/PartSaveStatusType';

class PlayroomFinalInformationPartViewModel {
  id!: string;
  acceptLegalTerms!: boolean;
  acceptBusinessTerms!: boolean;
  activeByOwner!: boolean;
}

const validations = {
  playroom: {
    acceptLegalTerms: {},
    acceptBusinessTerms: {},
    activeByOwner: {},
  },
};

export default Vue.extend({
  components: { ValidatedCheckbox, ValidatedSwitch, ValidatedFormCard },
  validations,
  mixins: [validationMixin],
  props: {
    playroomId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playroom: {} as PlayroomFinalInformationPartViewModel,
      savedAcceptLegalTerms: false,
      savedAcceptBusinessTerms: false,
      ready: false,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    activeByOwnerIsDisabled(): boolean {
      // When active, there should be a chance to deactivate
      if (this.playroom.activeByOwner) {
        return false;
      }
      // Both must be accepted to activate
      return !this.playroom.acceptBusinessTerms || !this.playroom.acceptLegalTerms;
    },
  },
  methods: {
    loadData() {
      OwnerPlayroomService.getForEditFinalInformationPart(this.playroomId).then((res) => {
        this.playroom = res.value as PlayroomFinalInformationPartViewModel;
        this.ready = true;
        this.savedAcceptLegalTerms = this.playroom.acceptLegalTerms;
        this.savedAcceptBusinessTerms = this.playroom.acceptBusinessTerms;
        this.$emit('part-save-status-type-change', PartSaveStatusType.Unchanged);
      });
    },

    externalSave() {
      return (this.$refs.validatedFormCard as ValidatedFormCardType).externalSave();
    },

    async save(): Promise<boolean> {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var response = await OwnerPlayroomService.saveFinalInformationPart({ ...this.playroom });
        if (response.success) {
          this.loadData();
        }
        return response.success;
      } else {
        return false;
      }
    },
  },
});
